import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getOrderDetails,getGeofenceByFleetId,getFleetConfig } from "../API";
import {findNorthWestAndSouthEast} from "../utils"

import Header from "./Header";
import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";
import Footer from "./Footer";

function OrderDelivered() {
  const { orderID } = useParams();
  const [orderDetails, setOrderDetails] = useState();

  const loaderContainerStyle = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "10vh",
  };

  const loaderStyle = {
    border: "4px solid rgba(0, 0, 0, 0.1)",
    borderTop: "4px solid #000",
    borderRadius: "50%",
    width: "40px",
    height: "40px",
    animation: "spin 1s linear infinite",
  };

  const keyframesAnimation = `
    @keyframes spin {
      0% { transform: rotate(0deg); }
      100% { transform: rotate(360deg); }
    }
  `;

  useEffect(() => {
    getOrderDetails(orderID)
      .then((res) => {
        getGeofenceByFleetId(res?.data?.data?.fleetId).then((res)=>{
          const maxBoundCordinates = findNorthWestAndSouthEast(res.data.geofence)
          console.log(maxBoundCordinates)        })
        
        // localStorage.setItem("name", res?.data?.data?.name);
        // localStorage.setItem("email", res?.data?.data?.email);
        // localStorage.setItem("phone", res?.data?.data?.phone);
        // localStorage.setItem("pin", res?.data?.data?.pin);
        // if (res.data?.data?.statusCode === 9) {
        //   history(`/orderdelivered/${orderID}`);
        // }
        // if ([10, 14].includes(res.data?.data?.statusCode)) {
        //   history(`/ordercancel/${orderID}`);
        // }
        setOrderDetails(res.data.data);
      })
      .catch((err) => {
        console.log("Error ", err);
      });
  }, [orderID]);
  useEffect (() => {
    const fetchFleetConfig = async () => {
      try {
        const response = await getFleetConfig(orderID);
        const data = response?.data?.data
        localStorage.setItem("fleetConfig", JSON.stringify(data));
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchFleetConfig();
  },[orderID])

  const formattedDate = (date) => {
    const orderDate = new Date(date);
    const yyyy = orderDate.getFullYear();
    let mm = orderDate.getMonth() + 1; // Months start at 0!
    let dd = orderDate.getDate();
    if (dd < 10) {
      dd = "0" + dd;
    }
    if (mm < 10) {
      mm = "0" + mm;
    }
    return mm + "-" + dd + "-" + yyyy;
  };

  return (
    <>
      <div className="MainWhole-wrapper-container">
        <Header />
        <div className="Congratulation-Main-wrapper">
          <div className="OrderDelivered_page_thankYou_div_wrapper">
            <h3>
              {" "}
              Your Order was delivered.
              {!!Number(process.env.REACT_APP_FOODDELIVERY)&&
              (<span><br />Enjoy your meal!</span>)
              }
            </h3>
          </div>
          <div className="OrderDelivered_page_tick_div">
            <img src="/assets/images/noun-tick-317777.svg" alt="" />
          </div>
          <div>
            <h3 className="OrderDelivered_page_h3"> Order Delivered</h3>
          </div>
          <Timeline position="alternate" style={{ marginTop: "0px" }}>
            <TimelineItem>
              <TimelineSeparator>
                <TimelineDot variant="outlined" className="timeline_dot_red" />
                <TimelineConnector />
              </TimelineSeparator>
              <TimelineContent></TimelineContent>
            </TimelineItem>
            <div className="OrderDelivered_page_content_div">
              <h4>Order ID </h4>
              <p> {orderID}</p>
            </div>
            <TimelineItem>
              <TimelineSeparator>
                <TimelineConnector />
              </TimelineSeparator>
              <TimelineContent></TimelineContent>
            </TimelineItem>
            <div className="mb-20 OrderDelivered_page_content_div">
              <h4>Date </h4>
              {orderDetails ? (
                <p>{formattedDate(orderDetails?.createdAt)}</p>
              ) : (
                <div style={loaderContainerStyle}>
                  <style>{keyframesAnimation}</style>
                  <div style={loaderStyle}></div>
                </div>
              )}
            </div>
          </Timeline>
        </div>
      </div>
      <Footer orderdelivered={true} />
    </>
  );
}

export default OrderDelivered;
