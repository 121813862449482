import { useState, useEffect, useRef } from "react";
import { FiNavigation2 } from "react-icons/fi";
import mapboxgl from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";

function MapboxRobot(props) {
  const mapContainerRef = useRef(null); // Ref for the map container
  const mapRef = useRef(null); // Ref for the map instance
  const [robotMarker, setRobotMarker] = useState(null);
  const [dropMarker, setDropMarker] = useState(null);
  const [pickupMarker, setPickupMarker] = useState(null);
  const [pickupLocationPopup, setPickupLocationPopup] = useState(null);
  const [dropLocationPopup, setDropLocationPopup] = useState(null);
  const [userControllingMap, setUserControllingMap] = useState(false);

  useEffect(() => {
    mapboxgl.accessToken =
      "pk.eyJ1IjoibXJsYWtzaHlhZ3VwdGEiLCJhIjoiY2xpZWZlcTJjMGo4ZDNkczBwc2ZmcW1qMyJ9.Kurk7BS04invJcCwS0m4Pg";

    const initializeMap = () => {
      const newMap = new mapboxgl.Map({
        container: mapContainerRef.current,
        style: "mapbox://styles/mrlakshyagupta/climr57of00le01pgc0jjgfoh",
        zoom: process.env.REACT_APP_MAP_ZOOM,
        // maxBounds: [
        //   props?.maxBoundCordinates?.southwest,
        //   props?.maxBoundCordinates?.northeast
        // ],
      });

      mapRef.current = newMap;

      const robotMarkerDiv = document.createElement("div");
      robotMarkerDiv.style.width = "35px";
      robotMarkerDiv.style.height = "35px";
      robotMarkerDiv.style.borderRadius = "100%";
      robotMarkerDiv.style.backgroundImage =
        "url(/assets/images/robot-icon-2.svg)";

      const dropMarkerDiv = document.createElement("div");
      dropMarkerDiv.style.width = "35px";
      dropMarkerDiv.style.height = "35px";
      dropMarkerDiv.style.borderRadius = "100%";
      dropMarkerDiv.style.backgroundImage = "url(/assets/images/dropoff.svg)";

      const pickupMarkerDiv = document.createElement("div");
      pickupMarkerDiv.style.width = "35px";
      pickupMarkerDiv.style.height = "35px";
      pickupMarkerDiv.style.borderRadius = "100%";
      pickupMarkerDiv.style.backgroundImage =
        "url(/assets/images/PickUpLocation-icon.svg)";

      let newRobotMarker, newDropMarker, newPickupMarker;
      if (props?.robotActiveMission?.missionActions.length) {
        let missionMarkers = Array(
          props?.robotActiveMission?.missionActions.length
        ).fill(new mapboxgl.Marker(dropMarkerDiv), 0);
        missionMarkers.forEach((marker, index) => {
          marker
            .setLngLat([
              props?.robotActiveMission?.missionActions[index]?.longitude || 0,
              props?.robotActiveMission?.missionActions[index]?.latitude || 0,
            ])
            .addTo(newMap)
            .setPopup(
              new mapboxgl.Popup({ offset: 25 }).setHTML(
                `<div class="custom-popup"><p>${props?.robotActiveMission.missionActions[index]?.locationName}</p></div>`
              )
            )
            .setLngLat([
              props?.robotActiveMission.missionActions[index]?.longitude || 0,
              props?.robotActiveMission.missionActions[index]?.latitude || 0,
            ]);
        });
      }
      if (props?.robotLocation) {
        newRobotMarker = new mapboxgl.Marker(robotMarkerDiv)
          .setLngLat([
            props?.robotLocation?.longitudeSlam || 0,
            props?.robotLocation?.latitudeSlam || 0,
          ])
          .addTo(newMap);
        setRobotMarker(newRobotMarker);
      }
      if (props?.orderDropLocation) {
        newDropMarker = new mapboxgl.Marker(dropMarkerDiv)
          .setLngLat([0, 0])
          .addTo(newMap);
        const dropPopup = new mapboxgl.Popup({
          closeButton: false,
          closeOnClick: false,
        });

        dropPopup
          .setLngLat([0, 0])
          .addTo(newMap)
          .setHTML(
            `<div class="custom-popup"><p>${props?.orderDropLocation?.locationName?props?.orderDropLocation?.locationName:"Drop"}</p></div>`
          );
        setDropLocationPopup(dropPopup);
        setDropMarker(newDropMarker);
      }
      if (props?.orderPickupLocation) {
        newPickupMarker = new mapboxgl.Marker(pickupMarkerDiv)
          .setLngLat([0, 0])
          .addTo(newMap);

        const pickupPopup = new mapboxgl.Popup({
          closeButton: false,
          closeOnClick: false,
        });

        pickupPopup
          .setLngLat([0, 0])
          .addTo(newMap)
          .setHTML(
            `<div class="custom-popup"><p>${props?.orderDropLocation?.locationName?props?.orderDropLocation?.locationName:"Pickup"}</p></div>`
          );
        setPickupLocationPopup(pickupPopup);
        setPickupMarker(newPickupMarker);
      }
    };

    if (!mapRef.current) {
      initializeMap();
    }
  }, [mapRef, props]);
  const isLatitude = (num) => isFinite(num) && Math.abs(num) <= 90;

  const isLongitude = (num) => isFinite(num) && Math.abs(num) <= 180;

  useEffect(() => {
    if (robotMarker) {
      if (props?.robotLocation?.headingSlam || props?.robotLocation?.heading) {
        let rotation = parseFloat(props?.robotLocation?.headingSlam || 0);
        robotMarker.setRotation(rotation).setRotationAlignment("map");
      }
    }

    if (dropMarker) {
      dropMarker
        .setLngLat([
          parseFloat(props?.orderDropLocation?.longitude) || 0,
          parseFloat(props?.orderDropLocation?.latitude) || 0,
        ])
        .setRotationAlignment("map");

      dropLocationPopup
        .setLngLat([
          props?.orderDropLocation?.longitude || 0,
          props?.orderDropLocation?.latitude || 0,
        ])
        .setHTML(
          `<div class="custom-popup"><p>${props?.orderDropLocation?.locationName?props?.orderDropLocation?.locationName:"Drop"}</p></div>`
        );
    }

    if (pickupMarker) {
      pickupMarker
        .setLngLat([
          parseFloat(props?.orderPickupLocation?.longitude) || 0,
          parseFloat(props?.orderPickupLocation?.latitude) || 0,
        ])
        .setRotationAlignment("map");
      pickupLocationPopup
        .setLngLat([
          props?.orderPickupLocation?.longitude || 0,
          props?.orderPickupLocation?.latitude || 0,
        ])
        .setHTML(
          `<div class="custom-popup"><p>${props?.orderDropLocation?.locationName?props?.orderDropLocation?.locationName:"Drop"}</p></div>`
        );
    }

    if (
      !userControllingMap &&
      !!Number(process.env.REACT_APP_USER_MAP_CONTROL) &&
      isLatitude(props?.orderDropLocation?.latitude) &&
      isLongitude(props?.orderDropLocation?.longitude) &&
      isLatitude(props?.robotLocation?.latitudeSlam) &&
      isLongitude(props?.robotLocation?.longitudeSlam) &&
      isLatitude(props?.orderPickupLocation?.latitude) &&
      isLongitude(props?.orderPickupLocation?.longitude)
    ) {
      const bounds = new mapboxgl.LngLatBounds(
        [
          props?.robotLocation?.longitudeSlam,
          props?.robotLocation?.latitudeSlam,
        ],
        [
          props?.orderPickupLocation?.longitude,
          props?.orderPickupLocation?.latitude,
        ]
      );

      mapRef.current.fitBounds(bounds, {
        padding: 100,
        duration: 200,
      });
    } else {
      const bounds = new mapboxgl.LngLatBounds(
        props?.maxBoundCordinates?.southwest,
        props?.maxBoundCordinates?.northeast
      );

      mapRef.current.fitBounds(bounds, {
        padding: 80,
        duration: 200,
      });
    }
  }, [
    props,
    props?.orderDropLocation,
    props?.robotLocation,
    robotMarker,
    dropMarker,
    pickupMarker,
    dropLocationPopup,
    userControllingMap,
  ]);

  return (
    <div style={{ height: "100%", position: "relative" }}>
      {userControllingMap &&
      !!Number(process.env.REACT_APP_USER_MAP_CONTROL) ? (
        <div
          className={`cursor-pointer flex items-center gap-2 sidebar bg-[#00B7D4] text-white p-[6px_12px] z-[1] absolute bottom-0 left-0 m-1 rounded-[4px] h-[30px]`}
          onClick={() => setUserControllingMap(false)}
        >
          <FiNavigation2 strokeWidth={3} />
          <span className="text-xs font-bold uppercase">Re-centre</span>
        </div>
      ) : null}
      <div
        ref={mapContainerRef}
        id="map-container-id"
        className="map-container"
        style={{
          height: "100%",
          position: "absolute",
          width: "100%",
        }}
      ></div>
    </div>
  );
}

export default MapboxRobot;
